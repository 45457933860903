export const English = {
  "Flash Technologies L.L.C-FZ": "Flash Technologies L.L.C-FZ",
  "Audited by": "Audited by",
  Flash: "Flash",
  Technologies: "Technologies",
  "Buy Flash": "Buy Flash",
  "Flash Technologies creates a money transfer ecosystem.":
    "Flash Technologies creates a money transfer ecosystem.",
  "Flash Transfer is the world's first platform that allows you to buy crypto with cash, transfer, or sell cryptocurrencies for cash through our network of merchants, with reasonable fees. Flash Wallet, our non-custodial wallet, has a smart contract that you can use to transfer your crypto in the event of death.  Find the best fees with Flash Dex, our aggregator swap & bridge!":
    "Flash Transfer is the world's first platform that allows you to buy crypto with cash, transfer, or sell cryptocurrencies for cash through our network of merchants, with reasonable fees. Flash Wallet, our non-custodial wallet, has a smart contract that you can use to transfer your crypto in the event of death. Find the best fees with Flash Dex, our aggregator swap & bridge !",
  CONTACT: "CONTACT",
  "Contact Us": "Contact Us",
  "Whether you have a question about one of our services or want to get involved in our mission, we are ready to help.":
    "Whether you have a question about one of our services or want to get involved in our mission, we are ready to help.",
  "Follow us:": "Follow us:",
  "First Name": "First Name",
  "Last Name": "Last Name",
  "Your Email Address": "Your Email Address",
  "Tell us about your project": "Tell us about your project",
  "Send Message": "Send Message",
  "Type Here": "Type Here",
  Our: "Our",
  "CORE TEAM": "CORE TEAM",
  "has worked with": "has worked with",
  "Core Team": "Core Team",
  "Aladdin CEO & Founder": "Aladdin CEO & Founder",
  "CEO & Founder of": "CEO & Founder of",
  Blocklabchain: "Blocklabchain",
  Hulk: "Hulk Cars",
  "Safi CCO & Co-Founder": "Safi CCO & Co-Founder",
  "Les Artisans d’Orient": "Les Artisans d’Orient",
  "Junior COO": "Junior COO",
  "Communication English & German. Works in":
    "Communication English & German. Works in",
  "Diplomatic Institution": "Diplomatic Institution",
  "Binance feed creator": "Binance feed creator",
  "Mimirk CPRO": "Mimirk CPRO",
  "Chief Public Relations Officer. Works in": "Chief Public Relations Officer. Works in",
  "Public Relations":
    "Public Relations",
  "Dev. Team": "Dev. Team",
  "Ernest CTO": "Ernest CTO",
  "Full stack & Blockchain Developer": "Full stack & Blockchain Developer",
  "Jawad Developer": "Jawad Developer",
  "Full stack Developer": "Full stack Developer",
  "Ahsan Developer": "Ahsan Developer",
  "Umair Developer": "Umair Developer",
  "Vilgelm Developer": "Vilgelm Developer",
  "Vadim Developer": "Vadim Developer",
  "Blockchain Developer": "Blockchain Developer",
  "Alex Developer": "Alex Developer",
  "Frontend Developer": "Front-end Developer",
  "Oleksii Developer": "Oleksii Developer",
  "Frontend & Blockchain Developer": "Frontend & Blockchain Developer",
  "Hassam Editor video": "Hassam Editor video",
  "Video creation and editor": "Video creation and editor",
  "Runnart Designer": "Runnart Designer",
  "Administrative graphic": "Administrative graphic",
  "White paper": "White paper",
  "& App logo designer": "& App logo designer",
  "Eznau Illustrator": "Eznau Illustrator",
  "Graphic flyers, logos": "Graphic flyers, logos",
  "NFT for all products of": "NFT for all products of",
  "Flash Technologies": "Flash Technologies",
  "Numa Designer UI/UX": "Numa Designer UI/UX",
  "Flash Transfer": "Flash Transfer",
  "Flash Wallet App": "Flash Wallet App",
  "UI/UX designer": "UI/UX designer",
  "ITO Designer": "ITO Designer",
  "for": 'for',
  "Products": 'Products',
  FAQ: "FAQ",
  "Community Manager": "Community Manager",
  "What is Flash technologies?": "What is Flash technologies?",
  "Flash Technologies is the name that represents all Flash projects.":
    "Flash Technologies is the name that represents all Flash projects.",
  "How can I buy Flash?": "How can I buy Flash?",
  "You can buy Flash token directly on":
    "You can buy Flash token directly on ",
  "What is Flash Transfer?": "What is Flash Transfer?",
  "Flash Transfer is the main project of Flash Technologies. We will be able to bank people who do not have a bank account or who wish to free themselves from traditional banks. We can exchange cash for crypto via our various partners. Users of this service will be able to make transfers worldwide.":
    "Flash Transfer is the main project of Flash Technologies. We will be able to bank people who do not have a bank account or who wish to free themselves from traditional banks. We can exchange cash for crypto via our various partners. Users of this service will be able to make transfers worldwide.",
  "What are the advantages of buying Flash ?":
    "What are the advantages of buying Flash ?",
  "Your Flash increases in value according to the evolution of the course. When Flash Technologies projects evolve, it has a positive impact on the price.":
    "Your Flash increases in value according to the evolution of the course. When Flash Technologies projects evolve, it has a positive impact on the price.",
  "On which site will the token be listed?":
    "On which site will the token be listed?",
  "The token will be listed on cex and dex.":
    "The token will be listed on cex and dex.",
  "Why transaction fees?": "Why transaction fees?",
  "Transaction fees support the project.":
    "Transaction fees support the project.",
  "Whitepaper EN": "Whitepaper EN",
  Presentation: "Presentation",
  "Using Flash Wallet": "Using Flash Wallet",
  SERVICES: "SERVICES",
  "(replace busd with ETH)": "(replace busd with ETH)",
  "Flash Ecosystem": "Flash Ecosystem",
  "The 'dead-wallet' function. This function enables the recovery of cryptos in another wallet in case of loss of seed phrase and in the event of a death of a crypto holder. It is embedded with a smart-contract linked to a timer that automatically triggers the transfer of funds out of the wallet after a period of time has elapsed. With extra security if ever the dead wallet doesn't have enough gas costs.":
    "The " +
    '"dead-wallet" ' +
    "function. This function enables the recovery of cryptocurrencies in another wallet in case of loss of the recovery phrase or in the event of the cryptocurrency holder's death. The wallet is equipped with a smart contract linked to a timer that triggers the transfer of funds out of the wallet after a period of time; the recipient must claim the cryptocurrencies. To use this feature, visit the flash-wallet.com application.",
  "Discover all our services.": "Discover all our services.",
  "Exchange your cash vs crypto or crypto vs cash. Buy directly cryptocurrency with our approved partners. You can also send your cryptocurrency and the receiver withdraws it in fiat all over the world using a Binance account or using your non custodial wallet ( Flash wallet, Metamask, Trust wallet ...).":
    "Exchange your cash vs crypto or crypto vs cash. Directly buy cryptocurrency from our approved partners. You can also send your cryptocurrency and the receiver withdraws it in fiat all over the world using a Binance account or using your non custodial wallet ( Flash wallet, Metamask, Trust wallet ...).",
  "Flash Dex": "Flash Dex",
  "Flash dex dapps is an all-in-one swap and bridge that allows users to swap their crypto assets from the same blockchain or from one blockchain to another. With the best possible direct market fees.":
    "Flash dex dapps is an all-in-one swap and bridge that allows users to swap their crypto assets from the same blockchain or from one blockchain to another. With the best possible direct market fees.",
  "Flash Technologies has built a fully integrated cryptocurrency wallet that holders and merchants can use to buy, sell, stake, earn rewards and browse on internet. Flash Wallet has a special function that is unique in cryptospace. A very important feature that users will enjoy everywhere in the world.":
    "Flash Technologies has built a fully integrated cryptocurrency wallet that holders and merchants can use to buy, sell, stake, earn rewards and browse the internet. Flash Wallet has a special function that is unique in cryptospace. A very important feature that users will enjoy everywhere in the world.",
  "Flash Wallet": "Flash Wallet",
  "Dead Wallet": "Flash Dead",
  "Flash Pay": "Flash Pay",
  "FlashPay, escrow agreements can be created quickly by buyer or seller. Once receipt of goods or services is confirmed funds are immediately transferred to the seller via the smart contract. In the event of a dispute, Flash Pay settles the dispute with the evidence received. At no time are the funds held by Flash Pay, everything is decentralized via the smart contract. Flash Pay can only validate or refuse the payment, nothing else.":
    "FlashPay, escrow agreements can be created quickly by buyer or seller. Once receipt of goods or services is confirmed funds are immediately transferred to the seller via the smart contract. In the event of a dispute, Flash Pay settles the dispute with the evidence received. At no time are the funds held by Flash Pay, everything is decentralized via the smart contract. Flash Pay can only validate or refuse the payment, nothing else.",
  "Customers and merchants have the opportunity to earn rewards by using the staking protocol. Each month stakers receive a % of our staking pool.":
    "Customers and merchants have the opportunity to earn rewards by using the staking protocol. Each month stakers receive a % of our staking pool.",
  "Flash Staking": "Flash Staking",
  "Flash audit will audit smart contracts (tokens, bridges, staking, all types of dapps) and blockchains of our partners/customers on all networks.":
    "Flash audit will audit smart contracts (tokens, bridges, staking, all types of dapps) and blockchains of our partners/customers on all networks. Each partner/customer who would like to be in our ecosystem will have to go through our security audit to make sure there is no malicious code.",
  "Each partner/customer who would like to be in our ecosystem will have to go through our security audit to make sure there is no malicious code.":
    "Each partner/customer who would like to be in our ecosystem will have to go through our security audit to make sure there is no malicious code.",
  "Flash Audit": "Flash Audit",
  "Flash Launchpad": "Flash Launchpad",
  "Flash pad is our launchpad for upcoming IDOs and allows crypto projects to create their tokens (Ethereum, BNB Chain, Polygon, Base, Avax, Cronos, Arbitrum, Blast) and raise funds in pre-sales or fair launches.You can also use the airdrop feature and the audit bot to quickly audit tokens.":
    "Flash pad is our launchpad for upcoming IDOs and allows crypto projects to create their tokens (Ethereum, BNB Chain, Polygon, Base, Avax, Cronos, Arbitrum, Blast) and raise funds in pre-sales or fair launches.You can also use the airdrop feature and the audit bot to quickly audit tokens.",
  "Secured blockchain": "Secured blockchain",
  "Single transaction": "Single transaction",
  "Deflationary token": "Deflationary token",
  "Replace a Bank Account": "Replace a Bank Account",
  "WE ARE SUPPORTED": "WE ARE SUPPORTED",
  "Flash Recharge Card": "Flash Recharge Card",
  "Flash Recharge": "Flash Recharge",
  "The Flash recharge is a prepaid card that allows users to top up their account in $ BUSD on the Flash wallet without any intermediary.":
    "The Flash recharge is a prepaid card that allows users to top up their account in the blockchain Coin (like ETH for Ethereum…) on the Flash wallet without any intermediary.",
  "Flash Recharge allows users to top up their balance on Flash Wallet.":
    "Flash Recharge allows users to top up their balance on Flash Wallet.",
  "The Flash recharge's cards will be printed at the time of purchase for an eco-responsible approach.":
    "The Flash recharge's cards will be printed at the time of purchase for an eco-responsible approach.",
  "Cash to crypto and vice versa.": "Cash to crypto and vice versa.",
  "DOWNLOAD FROM": "DOWNLOAD FROM",
  "Our Services": "Our Services",
  "App": "App",
  Tokenomics: "Tokenomics",
  Partnership: "Partnership",
  Roadmap: "Roadmap",
  NFT: "NFT",
  Team: "Team",
  Whitepaper: "Whitepaper",
  Audit: "Audit",
  'Presale': "Presale",
  "Become a Partner": "Become a Partner",
  "Privacy Policy": "Privacy Policy",
  "Legal Notice": "Legal Notice",
  License: "License",
  "Flash Technologies : Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, U.A.E.":
    "Flash Technologies : Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, U.A.E.",
  "Flash Technologies : 66 Avenue Des Champs Elysées,  75008, Paris.":
    "Flash Technologies : 66 Avenue Des Champs Elysées,  75008, Paris.",
  "Flash Technologies : Abidjan-Cocody, Les II Plateaux, 7ème Tranche, Abidjan.":
    "Flash Technologies : Abidjan-Cocody, Les II Plateaux, 7ème Tranche, Abidjan.",
  Pages: "Pages",
  Documents: "Documents",
  Legal: "Legal",
  "Join Our Newsletter": "Join Our Newsletter",
  "Your email address": "Your email address",
  Subscribe: "Subscribe",
  "Will send you weekly updates for your better finance management.":
    "Will send you weekly updates for your better finance management.",
  Partners: "Partners",
  Contact: "Contact",
  Staking: "Staking",
  "Invest in your Future": "Invest in your Future",
  "Clients can stake Flash or our approved partner's tokens for a certain period of time in order to earn rewards and bonuses":
    "Clients can stake Flash or our approved partner's tokens for a certain period of time in order to earn rewards and bonuses",
  Stake: "Stake",
  "Stake FLASH and receive rewards.": "Stake Flash and receive rewards.",
  "Complete Staking Term": "Complete Staking Term",
  "Users can choose the staking terms and hold their stake for s period of time to earn higher yield bonus returns.":
    "Users can choose the staking terms and hold their stake for a set period of time to earn higher yield bonus returns.",
  "Pay via Flash and partner tokens": "Pay via Flash and partner tokens",
  "After every successful stake, users are rewarded with Flash token as a farming bonus and partners tokens.":
    "After every successful stake, users are rewarded with Flash token as a farming bonus and partners tokens.",
  "Listing & Partners": "Listing & Partners",
  "Seen in the media": "Seen in the media",
  "GET YOUR NFT UTILITY": "GET YOUR NFT UTILITY",
  Airdrop: "Airdrop",
  "Each month receive Flash in your wallet.":
    "Each month receive Flash in your wallet.",
  "Holders of the AIRDROP NFTS receive Flash tokens every month. The amount differs depending on the rarity of the NFT. It also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed in the future.":
    "Holders of the AIRDROP NFTS receive Flash tokens every month. The amount differs depending on the rarity of the NFT also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed in the future.",
  "Ranking Increase": "Ranking Increase",
  "Will quickly increase the privilege ranking.":
    "Will quickly increase the privilege ranking.",
  "The NFTS RANKING INCREASE allow you to increase quickly your ranking points on Flash Transfer Website. The earned points depend on the rarity of the NFT. It also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed later.":
    "The NFTS RANKING INCREASE will allow you to quickly boost your ranking points on the flash transfer website. The earned points depend on the rarity of the NFT, it also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed later.",
  "Discount on transaction fees": "Discount on transaction fees",
  "Will entitle you to fees on money transfer site transactions.":
    "Will entitle you to fees on money transfer site transactions.",
  "The NFTS DISCOUNT ON TRANSACTION FEES provide discounts on transaction fees. The reduction is bigger depending on the rarity of the NFT. It also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed later.":
    "The NFTS DISCOUNT ON TRANSACTION FEES provide discounts on transaction fees. The reduction is bigger depending on the rarity of the NFT also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed later.",
  "Random effect": "Random effect",
  "Every 3 months or 6 months the function of the NFT changes.":
    "Every 3 months or 6 months the function of the NFT changes.",
  "The NFTS RANDOM EFFECT change abilities every 4 months. The benefit is greater depending on the rarity of the NFT. It also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed later.":
    "The NFTS RANDOM EFFECT change abilities every 4 months. The benefit is greater depending on the rarity of the NFT also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed later.",
  "Flash Technologies Roadmap": "Flash Technologies Roadmap",
  "August 2021": "August 2021",
  "Market study and meeting with professionals for the feasibility of the project.":
    "Market study and meeting with professionals for the feasibility of the project.",
  "September 2021": "September 2021",
  "Definition of the project and creation of the whitepaper.":
    "Definition of the project and creation of the whitepaper.",
  "October 2021": "October 2021",
  "Creation of the team of developers. Creation of the Flash Token Website.":
    "Creation of the team of developers. Creation of the Flash Token Website.",
  "November 2021": "November 2021",
  "Implementation of the Marketing strategy. Creation of the money transfer platform website. Meeting with influencers for the promotion of Flash Token. Launch of the Flash Token on the Bsc.":
    "Implementation of the Marketing strategy. Creation of the money transfer platform website. Meeting with influencers for the promotion of Flash Token. Launch of the Flash Token on the Bsc.",
  "December 2021": "December 2021",
  "Promotion of the Flash Token by influencers.":
    "Promotion of the Flash Token by influencers.",
  "January 2022": "January 2022",
  "Launch of the 1st NFT collection. Launch of the Flash Token on the Bsc.":
    "Launch of the 1st NFT collection. Launch of the Flash Token on the Bsc.",
  "February 2022": "February 2022",
  "Release of the beta version of the money transfer. Coming out of the Nft mint platform Deployment.":
    "Release of the beta version of the money transfer. Coming out of the Nft mint platform Deployment.",
  2022: "2022",
  "Launch of Flash Technologies website":
    "Launch of Flash Technologies website",
  "Unveiling of the first Flash Transfer partners":
    "Unveiling of the first Flash Transfer partners",
  "Launch of Flash Audit": "Launch of Flash Audit",
  "Vip Club Launch (only for the 200 Nft)":
    "Vip Club Launch (only for the 200 Nft)",
  "Launch of the advertising campaign with influencers + new ambassadors":
    "Launch of the advertising campaign with influencers + new ambassadors",
  "Development of the Audit platform": "Development of the Audit platform",
  "Development of the Staking platform": "Development of the Staking platform",
  "Beta Launch Flash Transfer": "Beta Launch Flash Transfer",
  "Flash x Binance": "Flash x Binance",
  "Launch Flash Wallet extension & application Android + IOS":
    "Launch Flash Wallet extension & application Android + IOS",
  "AML compliance": "AML compliance",
  "Flash x Synaps": "Flash x Synaps",
  "Flash x Certik": "Flash x Certik",
  "Launch Flash Transfer extension & application Android + IOS":
    "Launch Flash Transfer extension & application Android + IOS",
  2023: "2023",
  2024: "2024",

  "Flash 3.0": "Flash",
  "Flash Token": "FTT Token",
  "Flash token is a governance token tied to the entire Flash Technologies ecosystem. 2% of all revenues (transaction fees) will be reinvested in the token in the form of a monthly Buyback. Flash token is also linked to NFT Airdrops, where holders will receive 1% of the monthly token volume directly. The token is cross-chain, meaning it can be purchased on multiple networks with the same address. It can also be transferred to other wallets without paying transfer fees.":
    "FTT token is a governance token tied to the entire Flash Technologies ecosystem. 2% of all revenues (transaction fees) will be reinvested in the token in the form of a monthly Buyback. FTT token is also linked to NFT 'Airdrops' where holders will receive 1% of the monthly token volume directly. The token is cross-chain, meaning it can be purchased on multiple networks with the same address. It can also be transferred to other wallets without paying transfer fees.",
  "Flash is a cross-chain token that aims to become a means of exchange within the Flash Technologies ecosystem, which includes services such as Flash-transfer.com, Flash Wallet, and more, so that regular users are not exposed to exorbitant transaction fees. One of the primary goals of Flash is to provide holders with the ability to trade at a lower cost. Additionally, it will provide various benefits based on the number of tokens held by the user. ":
    "Flash is a cross-chain token that aims to become a means of exchange within the Flash Technologies ecosystem, which includes services such as Flash-transfer.com, Flash Wallet, and more, so that regular users are not exposed to exorbitant transaction fees. One of the primary goals of Flash is to provide holders with the ability to trade at a lower cost. Additionally, it will provide various benefits based on the number of tokens held by the user. ",
  "Contract address": "Contract address",
  "Token name": "Token name",
  "Total Supply": "Total Supply",
  Symbol: "Symbol",
  "Available on": "Available on",
  "Audit Flash": "Audit Flash",
  Marketing: "Marketing",
  Liquidity: "Liquidity",
  Burn: "Burn",
  Rewards: "Rewards",
  DAYS: "DAYS",
  HOURS: "HOURS",
  MINUTES: "MINUTES",
  SECONDS: "SECONDS",
  "Integration of dead wallet feature in Flash Wallet": "Integration of dead wallet feature in Flash Wallet",
  "Launch of the Launchpad": "Launch of the Launchpad",
  "Opening of our branch in France": "Opening of our branch in France",
  "New partner in money transfer": "New partner in money transfer",
  "Finalization of the Flash Transfer website": "Finalization of the Flash Transfer website",
  "Completion of the Flash Transfer application": "Completion of the Flash Transfer application",
  "Exchange listing": "Exchange listing",

  "Auditing Flash Transfer & Flash Wallet apps":
    "Auditing Flash Transfer & Flash Wallet apps",
  "Flash x Orange Money": "Flash x Orange Money",
  "Flash x MTN": "Flash x MTN",
  "Launch of Flash Payment": "Launch of Flash Payment",
  "Launch of Dead Wallet": "Launch of Dead Wallet",
  "Waiting for PSAN registration in France":
    "PSAN registration in France",
  "Waiting for VASP registration in UAE":
    "VASP registration in UAE",
};
