import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Typography,
  Grid,
  IconButton,
  CircularProgress
} from "@mui/material";
// import CircularProgress from '@mui/joy/CircularProgress';
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useStyles } from "../../../../Styles";
// import Flag from "../../assests/Logo/Flag.svg";

import AuditFlashImage from "../../../../assests/Images/audit-icon.png";
import BookImg from "../../../../assests/Logo/book.svg"
import cat from "../../../../assests/Images/cat.png";
import fw from "../../../../assests/Images/flash-wallet.png";
import led from "../../../../assests/Images/led.png";
import paper from "../../../../assests/Images/paper.png";
import right from "../../../../assests/Images/down-arrow.png";
import left from "../../../../assests/Images/up-arrow.png";

import copy from "../../../../assests/Images/copy.png";
import flashbtn from "../../../../assests/Images/flashbtn.png";

// import MobileDrawer from "../MobileDrawer";
import EN from "../../../../assests/pdf/WhitepaperFlashTechnologies.pdf";
import FR from "../../../../assests/pdf/WhitePaperFR.pdf";


import "./index.css";
// Chart
import { Doughnut } from 'react-chartjs-2';
import { Data, DataMobile, Options, chartLabel, transferChartLabel, TransferChartData } from './chartData'
import { Chart, ArcElement, Legend } from 'chart.js'
import { useSelector } from 'react-redux';
import { Languagemodel } from '../../../../Slice/translateSlice';
Chart.register(ArcElement, Legend);

const Tokenomics = () => {
  const discount = '0x88fA341d1C61F6723491dCeB56eE09f1FdA6d972';
  const [copied, setCopied] = useState(false);
  const CircleTrack = useMediaQuery('(min-width : 600px)');
  const ArrowTrack = useMediaQuery('(min-width : 1199px)');
  const TokenomicsMobile = useMediaQuery('(max-width : 420px)');
  const TokenomicsButton = useMediaQuery('(max-width : 600px)');
  const breakpoint899px = useMediaQuery('(min-width: 899px)');
  const classes = useStyles();
  const COLORS = ["#0088FE", "#03C39F", "#FFB827", "#FE8042"];
  const [isTransfer, SetisTransfer] = useState(true);
  const buyflashbtn = "https://pancakeswap.finance/swap?outputCurrency=0x88fA341d1C61F6723491dCeB56eE09f1FdA6d972";
  const AuditReport = "https://github.com/interfinetwork/project-delivery-data/blob/main/FTT/FTT_AuditReport_InterFi.pdf";
  const next = () => {
    SetisTransfer(!isTransfer);
  };
  const prev = () => {
    SetisTransfer(!isTransfer);
  };
  const lang = useSelector(Languagemodel)
  return (
    <>
      <Grid
        container
        sx={{
          position: "relative",
          color: "white",
          maxWidth: "100%",
          width: { lg: "1400px", xs: "100%" },
          margin: "0 auto",
          padding: { lg: "60px 0", xs: "30px 0 0 0" },
        }}
      >
        {/* className={`${isTransfer ? 'unselectable' : ''}`}> */}
        {
          isTransfer && <Typography variant="h3" className="soon">{/* Soon*/}</Typography>
        }
        {/* <img
          className={isTransfer ? `roadmap-image ShiftImage` : 'roadmap-image ShiftImage'}
          style={{
            position: "absolute",
            left: "0px",
            width: "40px",
            zIndex: 99999,
            bottom: `${ArrowTrack ? '45%' : '33%'}`,
            cursor: 'pointer',
          }}
          src={left} onClick={prev} /> */}
        {/* <img className={isTransfer ? `roadmap-image ShiftImage` : 'roadmap-image ShiftImage'}
          style={{
            position: "absolute",
            right: "0px",
            width: "40px",
            zIndex: 99999,
            bottom: `${ArrowTrack ? '45%' : '33%'}`,
            cursor: 'pointer',
          }}
          src={right} onClick={next} /> */}

        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: "flex", justifyContent: "center", position: 'relative' }}
        >
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontFamily: "Manrope",
                    fontSize: { lg: "50px", xs: "26px" },
                    fontWeight: "700",
                    lineHeight: "70px",
                    letterSpacing: "0em",
                    marginBottom: "20px",
                    transform: { lg: "scale(0.8)", xs: "scale(none)" },
                  }}
                  className={isTransfer ? classes.blurTransferComponent : ''}
                >
                  {lang["Tokenomics"]}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "0px 20px",
            position: "relative"
          }}
        // style={{filter:`${isTransfer && 'blur(10px)'}`}}
        >
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              justifyContent: "center",
              transform: { lg: "scale(0.8)", xs: "scale(none)" },
            }}
          >
            <Grid
              item
              // xs={12}
              md={6}
              sx={{
                display: { lg: "flex", xs: "flex" }
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%" }
                }}
                // style={{width:"427px"}}
                className={isTransfer ? classes.blurTransferComponent : ''}>
                <Typography
                  sx={{
                    color: "white",
                    fontFamily: "Manrope",
                    fontStyle: "normal",
                    fontWeight: 'bold',
                    fontSize: { lg: "50px", xs: "18px" },
                    display: 'flex',
                    flexDirection: 'row'
                    // lineHeight: "140%",
                    // paddingRight: "130px",
                  }}
                >
                  {/* {CircleTrack ? 'TOKEN FLASH' : 'Flash Transfer'} */}
                  {/* Tokenomics */}
                  <Typography sx={{
                    color: "#014CEC",
                    fontStyle: 'inherit',
                    fontSize: 'inherit',
                    fontWeight: 'inherit',

                  }}> {isTransfer ? lang['Flash 3.0'] : lang['Flash Token']}</Typography>
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Manrope",
                    fontSize: { lg: "20px", xs: "12px" },
                    fontWeight: "400",
                    lineHeight: "32px",
                    whiteSpace: 'pre-wrap',
                    letterSpacing: "0em",
                    textAlign: "left",
                    // paddingRight: { lg: "130px", xs: "0px" },
                  }}
                >
                  {!isTransfer ?
                    <>{lang["Flash token is a governance token tied to the entire Flash Technologies ecosystem. 2% of all revenues (transaction fees) will be reinvested in the token in the form of a monthly Buyback. Flash token is also linked to NFT Airdrops, where holders will receive 1% of the monthly token volume directly. The token is cross-chain, meaning it can be purchased on multiple networks with the same address. It can also be transferred to other wallets without paying transfer fees."]}
                      <div style={{ width: '100%' }} className="tmpBlock"></div></> : lang["Flash is a cross-chain token that aims to become a means of exchange within the Flash Technologies ecosystem, which includes services such as Flash-transfer.com, Flash Wallet, and more, so that regular users are not exposed to exorbitant transaction fees. One of the primary goals of Flash is to provide holders with the ability to trade at a lower cost. Additionally, it will provide various benefits based on the number of tokens held by the user. "]
                  }
                </Typography>
                <Box>
                  <Grid item md={12} >
                    <Typography
                      sx={{
                        fontFamily: "Manrope",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: { lg: "18px", xs: "12px" },
                        lineHeight: "160%",
                        display: "block",
                        margin: "30px 0",
                        color: "#AB9FF2",
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '90%'
                      }}
                    >
                      Solana
                      <Typography
                        sx={{
                          color: "#AB9FF2",
                          // display: "inline",
                          fontSize: { lg: "18px", xs: "12px" },
                          position: "relative",
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        {/* {discount} */}
                        {/* <img src={copy}/> */}
                        {!isTransfer ? discount : '7s.....................................................................................'}
                        {
                          <>
                            <div
                              className="discount-copied"
                              style={{
                                //                              display: `${copied ? 'block' : 'none'}`,
                                opacity: '0',
                                animationName: `${copied ? 'clipbooard' : ''}`,
                                animationDuration: '2s',
                                position: "absolute",
                                bottom: '18px',
                                right: '0px'
                              }}
                            >
                              Copied!
                            </div>
                            <CopyToClipboard text={!isTransfer ? discount : '0xBb19DA2482308ec02a242ACED4Fe0f09D06b12A7'} onCopy={() => setCopied(!copied)}>
                              <img src={copy} style={{
                                cursor: 'pointer',
                                marginLeft: "10px"
                              }} />
                            </CopyToClipboard>
                          </>
                        }
                      </Typography>
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Manrope",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: { lg: "18px", xs: "12px" },
                        lineHeight: "160%",
                        display: "block",
                        margin: "30px 0",
                        color: "#014CEC",
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '90%'
                      }}
                    >
                      Base
                      <Typography
                        sx={{
                          color: "#014CEC",
                          display: "inline",
                          fontSize: { lg: "18px", xs: "12px" },
                          position: "relative",
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          // width: '350px'
                        }}
                      >
                        {/* {discount} */}
                        {/* <img src={copy}/> */}
                        {!isTransfer ? discount : '0xBb19DA2482308ec02a242ACED4Fe0f09D06b12A7'}
                        {
                          <>
                            <div
                              className="discount-copied"
                              style={{
                                //                              display: `${copied ? 'block' : 'none'}`,
                                opacity: '0',
                                animationName: `${copied ? 'clipbooard' : ''}`,
                                animationDuration: '2s',
                                position: "absolute",
                                bottom: '18px',
                                right: '0px'
                              }}
                            >
                              Copied!
                            </div>
                            <CopyToClipboard text={!isTransfer ? discount : '0xBb19DA2482308ec02a242ACED4Fe0f09D06b12A7'} onCopy={() => setCopied(!copied)}>
                              <img src={copy} style={{
                                cursor: 'pointer',
                                marginLeft: "10px"
                              }} />
                            </CopyToClipboard>
                          </>
                        }
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Grid
                      item
                      md={6}
                      sx={{
                        marginBottom: "30px !important",
                        flexBasis: "max-content !important",
                        paddingRight: { lg: "120px !important", xs: "0px" },
                      }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                          width: "200px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Manrope",
                            fontWeight: "400",
                            fontSize: { lg: "14px", xs: "12px" },
                            lineHeight: "160%",
                            color: "#85898F",
                          }}
                        >
                          {lang["Token name"]} :
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Manrope",
                            fontWeight: "700",
                            fontSize: { lg: "18px", xs: "14px" },
                            lineHeight: " 160%",
                          }}
                          component="h4"
                        >
                          {!isTransfer ? lang["Flash Token"] : lang["Flash 3.0"]}  {/* mobile */}
                        </Typography>
                      </Box>

                      <Box sx={{}}>
                        <Typography
                          sx={{
                            fontFamily: "Manrope",
                            fontWeight: "400",
                            fontSize: { lg: "14px", xs: "12px" },
                            lineHeight: "160%",
                            color: "#85898F",
                          }}
                        >
                          {lang["Total Supply"]} :
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Manrope",
                            fontWeight: "700",
                            fontSize: { lg: "18px", xs: "14px" },
                            lineHeight: " 160%",
                          }}
                          component="h4"
                        >
                          {isTransfer ? '100.000.000' : '1.000.000.000'}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sx={{
                        width: "400px !important ",
                        maxWidth: "400px !important",
                        flexBasis: "auto !important",
                      }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Manrope",
                            fontWeight: "400",
                            fontSize: { lg: "14px", xs: "12px" },
                            lineHeight: "160%",
                            color: "#85898F",
                          }}
                        >
                          {lang["Symbol"]} :
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Manrope",
                            fontWeight: "700",
                            fontSize: { lg: "18px", xs: "14px" },
                            lineHeight: " 160%",
                          }}
                          component="h4"
                        >
                          {!isTransfer ? "FTT" : "Flash"}
                        </Typography>
                      </Box>

                      <Box sx={{}}>
                        <Typography
                          sx={{
                            fontFamily: "Manrope",
                            fontWeight: "400",
                            fontSize: { lg: "14px", xs: "12px" },
                            lineHeight: "160%",
                            color: "#85898F",
                          }}
                        >
                          {lang["Available on"]} :
                        </Typography>
                        <Typography
                          component="h4"
                          sx={{
                            fontFamily: "Manrope",
                            fontWeight: "700",
                            fontSize: { lg: "16px", xs: "14px" },
                            lineHeight: " 160%",
                          }}
                        >
                          {isTransfer ? 'Solana & Base' : 'BNB Chain'}
                        </Typography>
                      </Box>
                    </Grid>

                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: { lg: 'row', xs: 'column' },
                      marginBottom: "30px !important",
                      gap: '10px',
                      flexBasis: "max-content !important",
                      paddingRight: { lg: "120px !important", xs: "0px" },
                    }}
                  >
                    <a href={isTransfer ? 'https://flash-dex.com/'
                      : buyflashbtn} target="__blank" style={{ textDecoration: 'none', cursor: 'pointer' }}
                  /*  className={!isTransfer ? '' : 'disabled'} */ >
                      <IconButton
                        className="iconbutton"
                        sx={{
                          height: { lg: "62px", xs: "37.3px" },
                          fontSize: { lg: "20px", xs: "14px" },
                          margin: "0px 5px",
                          fontWeight: 600,
                          lineHeight: "150%",
                          borderRadius: "69px",
                          color: "#0F0F0E",
                          padding: { lg: "15px 55px", xs: "15px 25px" },
                          width: CircleTrack ? "246px" : '160px',
                          backgroundColor: "#014CEC",
                        }}
                      /*</a>disabled={isTransfer}*/
                      >
                        <img src={flashbtn} className="btn_icon" />
                        {" "}{lang["Buy Flash"]}
                      </IconButton>
                    </a>
                    <a
                      href={isTransfer ? EN
                        : AuditReport}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <IconButton
                        className="iconbutton"
                        sx={{
                          height: { lg: "62px", xs: "37.3px" },
                          fontSize: { lg: "20px", xs: "14px" },
                          margin: "0px 5px",
                          fontWeight: 600,
                          lineHeight: "150%",
                          borderRadius: "69px",
                          color: "#0F0F0E",
                          padding: { lg: "15px 55px", xs: "15px 25px" },
                          // display: {
                          //   xs: 'none'
                          // },
                          width: CircleTrack ? "246px" : '160px',
                          backgroundColor: "#014CEC",
                        }}
                      >
                        <img src={BookImg} className="btn_icon" />
                        {lang["Whitepaper"]}
                      </IconButton>
                    </a>
                    <a
                      href={isTransfer ? 'https://skynet.certik.com/projects/flash-3.0'
                        : AuditReport}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <IconButton
                        className="iconbutton"
                        sx={{
                          height: { lg: "62px", xs: "37.3px" },
                          fontSize: { lg: "20px", xs: "14px" },
                          margin: "0px 5px",
                          fontWeight: 600,
                          lineHeight: "150%",
                          borderRadius: "69px",
                          color: "#0F0F0E",
                          padding: { lg: "15px 55px", xs: "15px 25px" },
                          width: CircleTrack ? "246px" : '160px',
                          backgroundColor: "#014CEC",
                        }}
                      >
                        <img src={AuditFlashImage} className="btn_icon" />
                        {lang["Audit"]}
                      </IconButton>
                    </a>

                  </Grid>

                </Box>
              </Box>
            </Grid>
            <Grid
              item
              // xs={12}
              md={6}
              sx={{
                display: "flex", justifyContent: "center", alignItems: "center",
              }}
            >
              <Grid xs={6} md={8}>
                <Box
                  // style={{marginLeft:70 + 'px'}}
                  sx={{
                    marginLeft: { lg: 70 + 'px', xs: 20 + 'px' },
                    width: { lg: "100%", xs: "80%" },
                    marginTop: "10px",
                    position: "relative"
                  }}
                  className="CircleManager">
                  {/* <img className="roadmap-image arrowImageReponsive"
                    style={{
                      position: "absolute",
                      right: "102%",
                      width: "40px",
                      zIndex: 99999,
                      cursor: 'pointer',
                    }}
                    src={left} onClick={prev}
                  />
                  <img className="roadmap-image arrowImageReponsive"
                    style={{
                      position: "absolute",
                      left: "216%",
                      width: "40px",
                      zIndex: 99999,
                      cursor: 'pointer',
                    }}
                    src={right} onClick={next}
                  /> */}
                  <Doughnut className={isTransfer ? `${classes.blurTransferComponent} doughnut-filter` : 'doughnut-filter'}
                    data={TokenomicsMobile ? DataMobile : Data}
                    options={Options}
                    plugins={[chartLabel]}
                    style={{ display: `${!isTransfer ? 'block' : 'none'}` }}
                  />
                  <Doughnut className={isTransfer ? `${classes.blurTransferComponent} doughnut-filter` : 'doughnut-filter'}
                    data={TransferChartData}
                    options={Options}
                    plugins={[transferChartLabel]}
                    style={{ display: `${!isTransfer ? 'none' : 'block'}` }} />
                </Box>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Grid >
    </>
  );
};

export default Tokenomics;
