import { useSelector } from "react-redux"
import { Languagemodel } from "../../../../Slice/translateSlice"
export default function Map3() {
    const lang = useSelector(Languagemodel)
    return (
        <div>
            <div style={{
                position: 'absolute',
                top: 0,
                width: "100%"
            }}>
                <p style={{
                    textAlign: 'center',
                    fontWeight: 600,
                    fontSize: "40px",
                    margin: 0,
                    color: "#014CEC",
                    lineHeight: 0,
                }}>2023</p>
            </div>
            <div style={{
                position: "absolute",
                top: "17px",
                width: "100%",
                height: "40px",
                paddingTop: "20px"
            }}>
                <p style={{
                    width: "350px",
                    textAlign: "center",
                    fontSize: "13px",
                    color: 'white',
                    margin: "auto",
                }}>{lang["Beta Launch Flash Transfer"]}</p>
            </div>
            <div style={{
                position: "absolute",
                top: "215px",
                width: "100%",
                height: "99px",
                margin: "auto"
            }}>
                <div style={{
                    width: "200px",
                    margin: "0px 74% 0px 14%",
                }}>
                    <p style={{ color: "white", textAlign: 'right', fontSize: "13px", margin: 0, fontWeight: 500 }}>
                        {lang["Launch Flash Wallet extension & application Android + IOS"]}
                    </p>
                </div>
            </div>

            <div style={{
                position: "absolute",
                top: "230px",
                width: "100%",
                height: "99px",
                margin: "auto"
            }}>
                <div style={{
                    width: "262px",
                    margin: "0px 16% 0px 65%",
                }}>
                    <p style={{ color: 'white', textAlign: 'left', fontSize: "12px", margin: 0, fontWeight: 500 }}>
                        {lang["Flash x Binance"]}
                    </p>
                </div>
            </div>


            <div style={{
                position: "absolute",
                top: "354px",
                width: "100%",
                height: "99px",
                margin: "auto"
            }}>
                <div style={{
                    width: "280px",
                    margin: "0px 74% 0px 0%",
                }}>
                    <p style={{ color: "white", textAlign: 'right', fontSize: "13px", margin: 0, fontWeight: 500 }}>
                        {lang["Flash x Synaps"]}
                    </p>
                </div>
            </div>

            <div style={{
                position: "absolute",
                top: "348px",
                width: "100%",
                height: "180px",
                margin: "auto"
            }}>
                <div style={{
                    width: "275px",
                    margin: "0px 8% 0px 73%",
                }}>
                    <p style={{ color: "white", textAlign: 'left', fontSize: "13px", margin: 0, fontWeight: 500 }}>
                        {lang["AML compliance"]}
                    </p>
                </div>
            </div>

            <div style={{
                position: "absolute",
                top: "480px",
                width: "100%",
                height: "99px",
                margin: "auto"
            }}>
                <div style={{
                    width: "245px",
                    margin: "0px 0px 0px -3%",
                }}>
                    <p style={{ color: "white", textAlign: 'right', fontSize: "13px", margin: 0, fontWeight: 400 }}>
                        {lang["Launch Flash Transfer extension & application Android + IOS"]}
                    </p>
                </div>
            </div>

            <div style={{
                position: "absolute",
                top: "489px",
                width: "100%",
                height: "99px",
                margin: "auto"
            }}>
                <div style={{
                    width: "219px",
                    margin: "0px 0% 0px 80%",
                }}>
                    <p style={{ color: "white", textAlign: 'left', fontSize: "13px", margin: 0, fontWeight: 500 }}>
                        {lang["Flash x Certik"]}
                    </p>
                </div>
            </div>
        </div>
    )
}
